import React from 'react'
import { PageProps } from 'gatsby'
import MainLayout from '../layouts/MainLayout'
import Contact from '../layouts/sections/Contact'
import ServiceDetail from '../layouts/sections/ServiceDetail'

const ServiceTemplate: React.FC<PageProps<unknown, Context>> = (props) => {
  return (
    <MainLayout
      fixed={true}
      phone={props.pageContext.contact.phone}
      address={props.pageContext.contact.address}
    >
      <ServiceDetail
        service={props.pageContext.service}
      />
      <Contact
        phone={props.pageContext.contact.phone}
        address={props.pageContext.contact.address}
      />
    </MainLayout>
  )
}

interface Context {
  service: {
    serviceName: string
  }
  contact: {
    address: string
    phone: string
  }
}

export default ServiceTemplate
