import React from 'react'
import tw, { styled } from 'twin.macro'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import BackgroundImg from 'gatsby-background-image-es5'
import SetWidthSection from '../containers/SetWidthSection'
import contentfulRichText from '../../lib/contentfulRichText'

const ServiceDetail: React.FC<Props> = (props) => {
  const bgStack = [
    'linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(0, 0, 0, 0))',
    props.service.backgroundImage.localFile.childImageSharp.fluid,
  ]

  let longDescription = null
  if (props.service.longdesc) {
    longDescription = contentfulRichText(JSON.parse(props.service.longdesc.raw), props.service.longdesc.references)
  }

  return (
    <div>
      <ServiceDetailBG fluid={bgStack} alt="hero banner">
        <SetWidthSection>
          <ServiceDetailContent fixed={props.fixed}>
            <ServiceDetailContainer>
              <Title>{props.service.serviceName}</Title>
              <Description>{longDescription}</Description>
              <CTAContainer>
                <FAQCTA to="/#faq">{props.service.pageCtaText}</FAQCTA>
              </CTAContainer>
            </ServiceDetailContainer>
          </ServiceDetailContent>
        </SetWidthSection>
      </ServiceDetailBG>
    </div>
  )
}

ServiceDetail.defaultProps = {
  fixed: false,
}

interface Props {
  service: {
    serviceName: string
    longdesc: {
      raw: string
      references: {
        contentful_id: string
        fluid: any
      }[]
    }
  }
  fixed?: boolean;
}

const ServiceDetailBG = tw(BackgroundImg)`bg-cover bg-center`
const ServiceDetailContent = styled.div`
  ${tw`relative mb-8 lg:px-6`};
  ${(props) => (props.fixed ? tw`mt-32 lg:mt-8` : null)};
`
const ServiceDetailContainer = tw.div`w-full mt-12 md:mt-32 lg:w-2/3 px-6 pb-20 mb-8 lg:mb-0 text-left`
const Title = tw.h1`text-shadow-md font-heading text-3xl tracking-tight leading-10 text-blue-900 sm:text-4xl sm:leading-none md:text-5xl`
const Description = styled.div`
  ${tw`prose lg:prose-xl`};
  ${tw`mt-3 max-w-md mx-auto text-lg text-blue-900 sm:text-xl md:mt-5 md:max-w-3xl`};
  text-shadow: 2px 2px 5px rgba(10, 10, 10, 0.3);
`
const CTAContainer = tw.div`flex justify-center lg:justify-start mt-12`
const FAQCTA = tw(
  AnchorLink,
)`inline-flex text-white bg-blue-700 border-0 py-2 px-6 focus:outline-none hover:bg-blue-400 rounded-sm text-lg shadow-xl transition-transform transform active:scale-95 duration-100`

interface Data {
  contentfulImage: {
    image: {
      localFile: {
        childImageSharp: {
          fluid: unknown;
        };
      };
    };
  };
}

export default ServiceDetail
